<template>
  <div class="row justify-content-center pt-5">
    <div class="col-3">
      <div v-if="!uploaded">
        <FileUpload
          v-model="file"
          :progress="progress"
          :error="error"
          :loading="uploading"
          :file-types="['csv', 'xls', 'xlsx']"
          class="bg-light mb-3"
          @input="onSelectFile"
          @reset="error = null"
        />
        <p v-if="$route.query.type === 'asset'" class="font-w600">
          <a class="text-primary" target="_blank" href="https://docs.google.com/spreadsheets/d/176Y6vIM1MoP8TOSNFSof7JT7tD5_-6S9CP36METIZas"
            ><i class="fad fa-file-spreadsheet mr-2"></i> Import Templates</a
          >
        </p>
        <p v-if="$route.query.type === 'contract'" class="font-w600">
          <a
            class="text-primary"
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/176Y6vIM1MoP8TOSNFSof7JT7tD5_-6S9CP36METIZas/view#gid=1324345584"
            ><i class="fad fa-file-spreadsheet mr-2"></i> Import Templates</a
          >
        </p>
      </div>
      <strong v-else><Spinner>File uploaded successfully. Processing...</Spinner></strong>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FileUpload from '@/components/FileUpload';
import Spinner from '@/components/Spinner';

export default {
  name: 'ImportUpload',
  components: {
    FileUpload,
    Spinner
  },
  data() {
    return {
      file: {},
      progress: null,
      error: null,
      uploading: false,
      uploaded: false
    };
  },
  mounted() {
    if (!this.$route.query.type) {
      this.$router.push({ name: 'import-list' });
    }
  },
  computed: {
    ...mapGetters({
      setup: 'import/setup',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    })
  },
  methods: {
    ...mapActions({
      getUploadUrl: 'import/getUploadUrl'
    }),
    ...mapMutations({
      setId: 'import/SET_ID',
      reset: 'import/RESET'
    }),
    async onSelectFile() {
      this.reset();

      const urlInfo = await this.getUploadUrl({ type: this.$route.query.type });

      this.uploading = true;

      const file = this.file.file.get('file');

      try {
        await this.$http.put(urlInfo.s3Url, file, {
          headers: {
            'Content-Type': file.type
          },
          uploadProgress: progressEvent => {
            this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });

        this.file.s3Key = urlInfo.s3Key;

        this.setId(urlInfo.id);

        this.uploaded = true;

        setTimeout(() => {
          this.$router.push({ name: 'import-mapping', params: { id: urlInfo.id } });
        }, 2000);
      } catch (e) {
        this.error = 'Could not upload file';
        console.log(e);
      }

      this.uploading = false;
    }
  }
};
</script>
