var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-center pt-5"
  }, [_c('div', {
    staticClass: "col-3"
  }, [!_vm.uploaded ? _c('div', [_c('FileUpload', {
    staticClass: "bg-light mb-3",
    attrs: {
      "progress": _vm.progress,
      "error": _vm.error,
      "loading": _vm.uploading,
      "file-types": ['csv', 'xls', 'xlsx']
    },
    on: {
      "input": _vm.onSelectFile,
      "reset": function reset($event) {
        _vm.error = null;
      }
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _vm.$route.query.type === 'asset' ? _c('p', {
    staticClass: "font-w600"
  }, [_vm._m(0)]) : _vm._e(), _vm.$route.query.type === 'contract' ? _c('p', {
    staticClass: "font-w600"
  }, [_vm._m(1)]) : _vm._e()], 1) : _c('strong', [_c('Spinner', [_vm._v("File uploaded successfully. Processing...")])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "text-primary",
    attrs: {
      "target": "_blank",
      "href": "https://docs.google.com/spreadsheets/d/176Y6vIM1MoP8TOSNFSof7JT7tD5_-6S9CP36METIZas"
    }
  }, [_c('i', {
    staticClass: "fad fa-file-spreadsheet mr-2"
  }), _vm._v(" Import Templates")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "text-primary",
    attrs: {
      "target": "_blank",
      "href": "https://docs.google.com/spreadsheets/d/176Y6vIM1MoP8TOSNFSof7JT7tD5_-6S9CP36METIZas/view#gid=1324345584"
    }
  }, [_c('i', {
    staticClass: "fad fa-file-spreadsheet mr-2"
  }), _vm._v(" Import Templates")]);
}]

export { render, staticRenderFns }